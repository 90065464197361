// Import vendors base
require('pace-js');
require('../theme/smartadmin/plugins/jquery-ui-cust/jquery-ui-cust');
const { default: Popper } = require('popper.js');
Object.assign(window, { Popper });
require('bootstrap');
const bootbox = require('../theme/smartadmin/plugins/bootbox/bootbox-cust');
Object.assign(window, { bootbox });
require('../theme/smartadmin/plugins/bootbox/bootbox-config');
require('../theme/smartadmin/plugins/jquery-snippets/jquery-snippets');
const { Cowboy: { throttle, debounce } } = require('jquery-throttle-debounce');
Object.assign($, { throttle, debounce });
require('@oroinc/elevatezoom/jquery.elevatezoom');
require('jquery-mask-plugin');
require('jquery-slimscroll');
require('node-waves');
require('../theme/smartadmin/plugins/smartpanels/smartpanels');
require('jstree');

// Import vendors datagrid
require('datatables.net');
require('datatables.net-bs4');
require('../theme/smartadmin/plugins/datatables/datatables.styles.app');
require('datatables.net-autofill');
require('datatables.net-autofill-bs4');
require('datatables.net-buttons');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');
require('datatables.net-buttons/js/buttons.colVis.js');
require('../theme/smartadmin/plugins/datatables/datatables.styles.buttons.app');
require('datatables.net-colreorder');
require('datatables.net-colreorder-bs4');
require('datatables.net-fixedcolumns');
require('datatables.net-fixedcolumns-bs4');
require('datatables.net-fixedheader');
require('datatables.net-fixedheader-bs4');
require('datatables.net-keytable');
require('datatables.net-keytable-bs4');
require('datatables.net-responsive');
require('datatables.net-responsive-bs4');
require('datatables.net-rowgroup');
require('datatables.net-rowgroup-bs4');
require('datatables.net-rowreorder');
require('datatables.net-rowreorder-bs4');
require('datatables.net-scroller');
require('datatables.net-scroller-bs4');
require('datatables.net-select');
require('datatables.net-select-bs4');
require('../theme/smartadmin/plugins/datatables-alteditor/datatables-alteditor');

// Import vendors custom
require('../theme/smartadmin/plugins/json-path-picker/jsonpath-picker');
require('i18next-client');

// Import vendors addons statistics
require('dygraphs');
require('flot/jquery.flot');
require('flot/jquery.colorhelpers');
require('flot/jquery.flot.canvas');
require('flot/jquery.flot.categories');
require('flot/jquery.flot.crosshair');
require('flot/jquery.flot.errorbars');
require('flot/jquery.flot.fillbetween');
require('flot/jquery.flot.image');
require('flot/jquery.flot.navigate');
require('flot/jquery.flot.pie');
require('flot/jquery.flot.resize');
require('flot/jquery.flot.selection');
require('flot/jquery.flot.stack');
require('flot-spline/js/jquery.flot.spline');
require('flot/jquery.flot.symbol');
require('flot/jquery.flot.threshold');
require('jquery.flot.tooltip/js/jquery.flot.tooltip');
require('flot/jquery.flot.time');
const Chart = require('chart.js');
Object.assign(window, { Chart });
require('chartist');
const D3 = require('d3');
Object.assign(window, { D3 });
const C3 = require('c3');
Object.assign(window, { C3 });
require('peity');
require('../theme/smartadmin/plugins/peity/jquery.peity.config');
require('jquery-sparkline');
require('../theme/smartadmin/plugins/jquery-sparkline/jquery-sparkline.config');
require('easy-pie-chart');
require('../theme/smartadmin/plugins/easy-pie-chart/jquery.easypiechart.config');

// Import vendors masonry
const Masonry = require('masonry-layout');
Object.assign(window, { Masonry });

// Import vendors import
require('jszip');
require('pdfmake/build/pdfmake');
require('pdfmake/build/vfs_fonts');

// Import vendors notifications
const toastr = require('toastr');
Object.assign(window, { toastr });
require('es6-promise-polyfill');
require('sweetalert2');

// Import vendors form
require('bootstrap-datepicker');
require('bootstrap-daterangepicker');
const { Dropzone } = require('dropzone');
Object.assign(window, { Dropzone });
require('jquery-validation');
require('ion-rangeslider');
require('cropperjs');
require('jquery-cropper');
require('select2');
// const Markdown = require('markdown/lib/markdown');
// Object.assign(window, { Markdown });
require('nouislider');
require('smartwizard/dist/js/jquery.smartWizard');
require('smartwizard/dist/css/smart_wizard.css');
require('../theme/smartadmin/plugins/timepicker/index');

// Import vendors miscellaneous
// require('@fullcalendar/core');
// require('@fullcalendar/daygrid');
// require('@fullcalendar/list');
// require('@fullcalendar/timegrid');
// require('@fullcalendar/interaction');
// require('../theme/smartadmin/plugins/@fullcalendar/bootstrap-main-cust');
// require('justifiedGallery');
require('jquery-mousewheel');
// require('lightgallery/src/js/lightgallery');
// require('lightgallery/src/js/lightgallery');
// require('lightgallery/modules/lg-autoplay');
// require('lightgallery/modules/lg-fullscreen');
// require('lightgallery/modules/lg-hash');
// require('lightgallery/modules/lg-pager');
// require('lightgallery/modules/lg-thumbnail');
// require('lightgallery/modules/lg-zoom');
// require('../theme/smartadmin/plugins/smartvoice/smartvoice-config');
// require('../theme/smartadmin/plugins/smartvoice/smartvoice');
// require('jqvmap/dist/jquery.vmap');
// require('jqvmap/dist/maps/jquery.vmap.world');
// require('jqvmap/dist/maps/jquery.vmap.algeria');
// require('jqvmap/dist/maps/jquery.vmap.argentina');
// require('jqvmap/dist/maps/jquery.vmap.brazil');
// require('jqvmap/dist/maps/jquery.vmap.canada');
// require('jqvmap/dist/maps/jquery.vmap.europe');
// require('jqvmap/dist/maps/jquery.vmap.france');
// require('jqvmap/dist/maps/jquery.vmap.germany');
// require('jqvmap/dist/maps/jquery.vmap.greece');
// require('jqvmap/dist/maps/jquery.vmap.iran');
// require('jqvmap/dist/maps/jquery.vmap.iraq');
// require('jqvmap/dist/maps/jquery.vmap.russia');
// require('jqvmap/dist/maps/jquery.vmap.tunisia');
// require('jqvmap/dist/maps/jquery.vmap.turkey');
// require('jqvmap/dist/maps/jquery.vmap.usa');
require('nestable');
const moment = require('moment');
Object.assign(window, { moment });

// Import app
require('../theme/smartadmin/js/_config/app.config.js');
require('../theme/smartadmin/js/_modules/app.navigation.js');
require('../theme/smartadmin/js/_modules/app.menu.slider.js');
require('../theme/smartadmin/js/_modules/app.init.js');
require('../theme/smartadmin/js/_modules/app.layout.shortcuts.js');
require('../theme/smartadmin/js/_modules/app.resize.trigger.js');
require('../theme/smartadmin/js/_modules/app.scroll.trigger.js');
require('../theme/smartadmin/js/_modules/app.domReady.js');
require('../theme/smartadmin/js/_modules/app.orientationchange.js');
require('../theme/smartadmin/js/_modules/app.window.load.js');
require('../theme/smartadmin/js/_config/app.colors.js');
require('../theme/smartadmin/js/_init/header_filters');

// Import styles
require('./index.scss');

const { startStimulusApp } = require('@symfony/stimulus-bridge');
const stimulusApp = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!../controllers',
    true,
    /\.[jt]sx?$/
));

stimulusApp.debug = typeof process !== 'undefined' && process.env.NODE_ENV === 'development' && !process.env.STIMULUS_NOT_DEBUG;

$.extend($.validator.defaults, {
    ignore: ''
});

require('../bundles/archive/filemanager');
require('../theme/smartadmin/js/rhsuite');
require('../utils/posthog');

document.addEventListener('DOMContentLoaded', function () {
    $('[data-toggle="tooltip"]').tooltip()

    document.addEventListener('keydown', (evt) => {
        if (evt.altKey) document.body.classList.add('key-alt');
        if (evt.ctrlKey) document.body.classList.add('key-ctrl');
        if (evt.metaKey) document.body.classList.add('key-meta');
        if (evt.shiftKey) document.body.classList.add('key-shift');
    });

    document.addEventListener('keyup', (evt) => {
        if (!evt.altKey) document.body.classList.remove('key-alt');
        if (!evt.ctrlKey) document.body.classList.remove('key-ctrl');
        if (!evt.metaKey) document.body.classList.remove('key-meta');
        if (!evt.shiftKey) document.body.classList.remove('key-shift');
    });
});
